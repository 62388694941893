import * as React from "react";

import { Layout } from "components";
import {
  OurMission,
  OurPeople,
  OurStory,
  WhoWeArePageHeader,
  CoreValues,
} from "templates/who-we-are";

const WhoWeArePage = () => (
  <Layout title="Who We Are">
    <WhoWeArePageHeader />
    <OurStory />
    <OurMission />
    <OurPeople />
    <CoreValues />
  </Layout>
);

export default WhoWeArePage;
