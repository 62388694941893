import { Container, Text } from "components";
import React from "react";
import ctl from "@netlify/classnames-template-literals";
import ResponsibiltyIcon from "svgs/responsibility.svg";
import CreativeIcon from "svgs/creative-idea.svg";
import OpennessIcon from "svgs/openness.svg";
import TrustIcon from "svgs/trust.svg";

const CoreValues = () => {
  return (
    <section className={perksWrapperStyle}>
      <Container>
        <Text value={<>Our core values</>} variant="h2" />

        <div className={perksListStyle}>
          {perksList.map((perk, index) => {
            return (
              <div key={`perks_${index}`}>
                {perk.icon}
                <Text variant="h4" value={perk.title} />
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

const perksWrapperStyle = ctl(`
md:py-[90px]
py-[61px]
text-center
`);

const perksListStyle = ctl(`
grid
md:grid-cols-4
gap-[64px]
md:mt-[113px]
mt-[44px]
`);

const perkIconStyle = ctl(`
mx-auto
h-[56px]
mb-4
`);
const perksList = [
  {
    icon: <ResponsibiltyIcon className={perkIconStyle} />,
    title: "Integrity",
  },
  {
    icon: <CreativeIcon className={perkIconStyle} />,
    title: "Creative",
  },
  {
    icon: <OpennessIcon className={perkIconStyle} />,
    title: "Openness",
  },
  {
    icon: <TrustIcon className={perkIconStyle} />,
    title: "Responsibilty",
  },
];

export { CoreValues };
