import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { TimelineSteps } from "./timeline-steps";

const Timeline = () => {
  return (
    <>
      {ourStoryData.map((story, index) => {
        const isLast = index === ourStoryData.length - 1;

        return <TimelineSteps key={story.year} isLast={isLast} {...story} />;
      })}
    </>
  );
};

const ourStoryData = [
  {
    year: "2004- 2016",
    description: (
      <>
        <p className="mb-6">
          We started before the buzz—believing that using money could be
          simpler, seamless, and easier. Then, we morphed into providing modern
          banking experiences for leading banks, businesses, and individuals
          across the continent.{" "}
        </p>
      </>
    ),
    image: (
      <StaticImage
        alt="our growth timeline"
        src="../../../assets/images/jpegs/who-we-are/2004-2016.png"
      />
    ),
  },
  {
    year: "2017 - 2021",
    description:
      "Our focus evolved into empowering multiple businesses and individuals across different verticals, including financial inclusion, through its agency network.",
    image: (
      <StaticImage
        alt="our growth timeline"
        src="../../../assets/images/jpegs/who-we-are/2017-2021.png"
      />
    ),
  },
  {
    year: "2021 - Present",
    description:
      "Parkway is on track to shape personal finance across Africa, starting from Nigeria. We are deeply committed and focused on empowering individuals through our digital solutions. Our customer-centric approach and innovations are restructuring how people manage their finances, ensuring simplicity, security, and availability.",
    image: (
      <StaticImage
        alt="our growth timeline"
        src="../../../assets/images/jpegs/who-we-are/2021-present.png"
      />
    ),
  },
];

export { Timeline };
