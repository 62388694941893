const teamMembers = [
  {
    position: "Chief Executive Officer",
    name: "Emeka Onwuka",
  },
  {
    position: "Executive Director",
    name: "Emeka Onwugbufor",
  },
  {
    position: "Chief Technology Officer",
    name: "Opeyemi Ibuoye",
  },
  {
    position: "Head, People Ops & Admin",
    name: "Tolulope Olateju",
  },
  {
    position: "Head, Product Support",
    name: "Opeyemi Alonge",
  },
  {
    position: "Head, Finance",
    name: "Emeka Uzochukwu",
  },
  {
    position: "Head, Reconciliation",
    name: "Adetayo Adenuga",
  },
  {
    position: "Team Lead, Business",
    name: "Elvis Oruene",
  },
  {
    position: "Team Lead, Products",
    name: "Stephen Fregene",
  },
];

export { teamMembers };
