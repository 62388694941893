import { Text, Container, Br } from "components";
import React from "react";
import { Timeline } from "../timeline";

const OurStory = () => {
  return (
    <Container>
      <Text variant="h2" value="Our Story" />
      <Text
        variant="p18"
        className="lg:mb-[80px] mb-[60px] mt-3"
        value={
          <>
            <p className="mb-6">
              Over the past decade, we have led the way in providing
              best-in-class software solutions to financial institutions such as
              banks in Nigeria. However, with innovations, we identified the
              need to redefine financial convenience and reliability by directly
              offering access to advanced money management solutions to
              individuals through our various solutions.
            </p>
          </>
        }
      />

      <Timeline />
    </Container>
  );
};

export { OurStory };
