import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { teamMembers } from "config";
import { Container, Text } from "components";

const OurPeople = () => {
  return (
    <Container>
      <Text variant="h2" value="Our People" className="mb-6 lg:mb-[71px]" />

      <div className={gridStyle}>
        {teamMembers.map(item => (
          <div key={item.position}>
            <Text variant="h4" value={item.name} />
            <Text variant="p18" value={item.position} />
          </div>
        ))}
      </div>
    </Container>
  );
};

const gridStyle = ctl(`
  grid 
  xl:grid-cols-4
  grid-cols-2
  2xl:gap-x-[113px]
  gap-[34px]
  xl:gap-y-[113px]
  gap-y-[85px]
  xl:mb-[166px]
  mb-[113px]
`);

export { OurPeople };
