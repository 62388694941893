import { Br, Container, PageHeader } from "components";
import { StaticImage } from "gatsby-plugin-image";
import ctl from "@netlify/classnames-template-literals";
import React from "react";

const WhoWeArePageHeader = () => {
  return (
    <>
      <PageHeader
        title={<>We only win when you win</>}
        subheading={
          <>
            This promise can be cascaded down to achieving the satisfaction of
            each one of our users.
          </>
        }
      />
      <Container>
        <StaticImage
          src="../../../assets/images/jpegs/who-we-are/hero.jpeg"
          alt="people discussing in a meeting setting"
          className={imageStyle}
        />
      </Container>
    </>
  );
};

const imageStyle = ctl(`
  lg:mt-[-100px] 
  -mt-6 
  lg:mb-[88px] 
  mb-[45px]
`);

export { WhoWeArePageHeader };
