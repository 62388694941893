import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { Text } from "components/text";
import OurStoryIcon from "svgs/our-story-timeline.svg";

const TimelineSteps = ({ image, year, description, isLast }) => {
  return (
    <div className={sectionWrapStyle}>
      <div className={imageWrapStyle}>{image}</div>

      <div className={arrowAndTextWrapStyle}>
        <div className={iconWrapStyle}>
          <OurStoryIcon />
          <div className={`${lineStyle} ${isLast && "hidden"} `}></div>
        </div>

        <div>
          <Text
            variant="p18"
            value={year}
            weight="700"
            className="-mt-2 mb-2"
          />
          <Text
            variant="p16"
            value={description}
            className="lg:max-w-[678px]"
          />
        </div>
      </div>
    </div>
  );
};

const sectionWrapStyle = ctl(`
  flex
  flex-col
  lg:flex-row
  lg:gap-[65px]
  gap-[30px]
  pt-10
  lg:pt-[22px]
`);
const arrowAndTextWrapStyle = ctl(`
  flex
  lg:gap-[51px]
  gap-[44px]
  max-w-[391px] 
  sm:max-w-[80vw] 
  lg:max-w-auto
`);
const imageWrapStyle = ctl(`
  self-start 
  lg:max-w-[398px] 
  sm:max-w-[80vw] 
  max-w-[391px]
`);
const iconWrapStyle = ctl(`
  flex
  flex-col
  gap-[10px]
`);
const lineStyle = ctl(`
  lg:h-[243px]
  h-[187px]
  w-[1px]
  bg-primary-200
  ml-3
`);

export { TimelineSteps };
