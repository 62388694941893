import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { Br, Container, Text } from "components";
import MissionIcon from "svgs/mission.svg";
import VisionIcon from "svgs/vision.svg";

const OurMission = () => {
  return (
    <section className="bg-secondary-1200">
      <Container>
        <div className={sectionWrapStyle}>
          <div className={itemStyle}>
            <div className={`${iconwrapStyle} bg-secondary-700`}>
              <VisionIcon />
            </div>

            <div>
              <Text
                variant="h4"
                color="secondary-100"
                value="Vision"
                weight={700}
                className={headingStyle}
              />
              <Text
                variant="p18"
                color="secondary-100"
                value={
                  <>
                    Enable exclusive and efficient financial <Br on="desktop" />
                    services for Africans.
                  </>
                }
              />
            </div>
          </div>

          <div className={itemStyle}>
            <div className={`${iconwrapStyle} bg-[#F9956B]`}>
              <MissionIcon />
            </div>

            <div>
              <Text
                variant="h4"
                color="secondary-100"
                value=" Mission"
                weight={700}
                className={headingStyle}
              />
              <div id="our-team"></div>
              <Text
                variant="p18"
                color="secondary-100"
                value={
                  <>
                    Provide a reliable payment platform <Br on="desktop" />
                    for Africa.
                  </>
                }
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

const sectionWrapStyle = ctl(`
  flex
  flex-col
  justify-center
  lg:flex-row
  xl:gap-[250px]
  lg:gap-[150px]
  gap-[80px]
  mt-[179px]
  lg:mb-[162px]
  mb-[110px]
  py-[70px]
`);
const itemStyle = ctl(`
 flex
 gap-6
`);
const headingStyle = ctl(`
  md:pb-1 
`);
const iconwrapStyle = ctl(`
  w-[81px]
  h-[68px]
  flex 
  justify-center
  items-center
`);

export { OurMission };
